<template>
  <div>
    <!-- breadCrumb -->
    <!-- <v-breadcrumbs :items="breadcrumbList" divider="/">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
          {{ $t(item.text) }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs> -->
    <!-- breadCrumb -->
    <v-row>
      <v-col cols="12">
        <h2 class="title-page" v-if="data.title">
          <span class="main-color">{{ data.title.grade_name }}</span>
          |
          <span>{{ data.title.subject_name }}</span>
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" class="no-padding">
        <template>
          <v-expansion-panels>
            <v-expansion-panel
              v-for="semester in data.semesters"
              :key="semester"
            >
              <v-expansion-panel-header>
                <v-row>
                  <v-col md="8" cols="12">
                    <h3 class="title-panel">{{ semester.semster_name }}</h3>
                  </v-col>
                  <v-col md="3" cols="12">
                    <div class="details">
                      <span class="total"
                        >{{ $t("Total Mark:") }} {{ semester.total_mark }}</span
                      >
                      <span class="passing"
                        >{{ $t("Passing Mark:") }}
                        {{ semester.passing_mark }}</span
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          v-for="(header, index) in semester.headers"
                          :key="index"
                          class="text-left"
                        >
                          {{ header }}
                        </th>

                        <th class="text-left"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(items, index2) in semester.items"
                        :key="index2"
                      >
                        <td v-for="item in items" :key="item">
                          {{ item }}
                        </td>

                        <td>
                          <v-icon
                            @click="ShowActivity(index2)"
                            :title="$t('Edit')"
                            class="main-color"
                            >edit</v-icon
                          >
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
      </v-col>
    </v-row>

    <!-- snackBar -->
    <div class="text-center ma-2">
      <v-snackbar
        v-model="snack"
        :color="color"
        :timeout="timeout"
        :top="true"
        :right="true"
      >
        {{ text }}
        <v-btn color="pink" text @click="snack = false">{{
          $t("Close")
        }}</v-btn>
      </v-snackbar>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";

export default {
  name: "GradeYearsRelatedData",
  components: {},
  mixins: [validationMixin],
  data() {
    return {
      grade_year_id: "",
      academic_grade_subject_id: "",
      loading: true,
      data: [],

      /* snackBar */
      snack: false,
      text: this.$i18n.t("Item Deleted Successfully"),
      color: "success",
      timeout: 2000,
    };
  },
  watch: {},
  computed: {},
  methods: {
    getData() {
      axios
        .get(
          this.getApiUrl +
            "/school/getAcademicGradeSubjectQualifiers/" +
            this.academic_grade_subject_id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.data = response.data.data;
        });
    },
    ShowActivity(index) {
      // academic_grade_subject_id
      // quarter_id = index;
      this.$router.push(
        {
          path:
            "/gradeyears/" +
            this.grade_year_id +
            "/ShowActiveties/" +
            this.academic_grade_subject_id +
            "/" +
            index,
        },
        () => {}
      );

      console.log("quarter id", index);
    },
  },
  created() {
    this.grade_year_id = this.$router.currentRoute.params.grade_year_id;
    this.academic_grade_subject_id =
      this.$router.currentRoute.params.academic_grade_subject_id;
    this.getData();
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

// end search input
.title-page {
  margin-top: 15px;
}
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #eef3ff;
  margin-bottom: 33px;
}
.v-expansion-panel-content {
  background-color: #f7f7f7;
}
.title-panel {
  color: rgb(114, 151, 255);
  margin: 0;
  margin-top: 15px;
  padding: 0;
}
.details {
  margin-top: 6%;
  overflow: hidden;
}
.details span {
  color: #8d8a8a;
  font-size: 17px;
  font-weight: 700;
}
.details span.passing {
  float: right;
}
</style>
